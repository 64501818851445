@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,500;1,200&display=swap');

:root{
  --fonte-padrao: 'Montserrat', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--fonte-padrao)
}
