.fundoCapa {
  padding: 1em 1em;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.perfilFoto {
  width: 5em;
  display: block;
  padding: 0 1.5em;
}