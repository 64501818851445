html {
  background-color: rgb(255, 255, 255);
}
h1 {
  font-size: 1.2em;
  text-align: center;
  margin: 0.5em 0 0.5em 0;
  color: #303f4d;
}
label {
  display: block;
  color: rgb(48, 63, 77);
  font-size: 1em;
  font-weight: 400;
  margin: 0.5em 0;
}
main {
  padding: 1.5em;
}

form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
input {
  width: 40%;
  padding: 0.7em 0.9em;
  border: 1px solid rgb(213, 214, 219);
  border-radius: 5px;
  text-align: center;
}
input::placeholder {
  font-size: 1em;
  text-align: center;
}
.tabela {
  flex-direction: row;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1em 0;
}
.medidas {
  display: flex;
  margin: 0.5em;
  width: 40%;
  padding: 0.8em 2em;
}
.tituloMedidas {
  margin: 0.5em;
}
.botoes {
  display: flex;
  justify-content: center;
  padding: 0 1em 0 1em;
}
.botaoCalcular {
  font-size: 1em;
  font-weight: 500;
  border: none;
  background: rgb(47, 37, 189);
  border-radius: 5px;
  padding: 0.6em 7em;
  color: white;
}
.botaoCalcular:hover {
  background-color: blue;
  transform: scale(1.2);
}
.resultado {
  padding-bottom: 1em;
  font-size: 1.1em;
  text-align: center;
}

@media only screen and (min-width: 600px) and (max-width: 2560px) /* Tablet/Ipad Pro/Desktop */ {
  main {
    padding: 1.5em 2em;
    width: 50%;
    margin: auto;
  }
}
